import React from "react";
import { Helmet } from "react-helmet";
import Parallax from "../components/Parallax"

import "../styles/materialize.css";
import "../styles/styles.css";

import Layout from "../components/Layout";


export default function Home() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8"></meta>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        ></meta>
        <meta name="description" content="Onetic Sistemas S.L."></meta>
        <meta
          name="keywords"
          content="Onetic, sistemas, componentes, informático, tecnología, consultoría, soluciones, Madrid"
        ></meta>
        <meta name="author" content="Onetic Sistema S.L."></meta>
        <title>Onetic Sistemas</title>
        </Helmet>
      <header></header>
      <Layout>
        <Parallax imagen="computers.webp" color="light">
          <h1 className="text-lighten-2 white-text">
            Soluciones tecnológicas
          </h1>
            <h5 className="light">
              Ofrecemos servicios tecnológicos de alto nivel para empresa e
              instituciones de gran valor
            </h5>
          <p>
            <a
              href="mailto:info@onetic.es"
              id="download-button"
              className="black btn-small darken-4 pulse waves-effect waves-light white-text"
            >
              CONTACTAR
            </a>
          </p>
        </Parallax>
        <div className="container">
          <div className="section">
            <div className="row">
              <div className="col m4 s12">
                <div className="icon-block">
                  <h2 className="brown-text center">
                    <i className="black-text material-icons">wb_cloudy</i>
                  </h2>
                  <h5 className="center">Alta disponibilidad</h5>
                  <p className="light">
                    Ofrecemos soluciones en la nube que le ayudarán en la
                    transformación digital de su empresa.
                  </p>
                </div>
              </div>
              <div className="col m4 s12">
                <div className="icon-block">
                  <h2 className="brown-text center">
                    <i className="black-text material-icons">group</i>
                  </h2>
                  <h5 className="center">Basado en necesidades</h5>
                  <h5 className="center"></h5>
                  <p className="light"></p>
                  <p className="light">
                    Se evalúan las necesidades reales, teniendo en cuenta los
                    recursos actuales, optimizando costes y recursos.
                    <br />
                  </p>
                </div>
              </div>
              <div className="col m4 s12">
                <div className="icon-block">
                  <h2 className="brown-text center">
                    <i className="black-text material-icons">call_made</i>
                  </h2>
                  <h5 className="center">Capacidad de crecimiento</h5>
                  <p className="light">
                    Proyectos con vistas a futuro. No tendrá que empezar desde
                    cero ante la incapacidad de su infraestructura.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Parallax imagen="micro.webp" color="light">
          Nos ocupamos de todos los dispositivos, hardware y periféricos que
          pueda necesitar en el proyecto.
        </Parallax>
        <div className="container">
          <div className="section">
            <div className="row">
              <div className="col s12 center">
                <h3>
                  <i className="mdi-content-send brown-text"></i>
                </h3>
                <h4>Contacta</h4>
                <p className="left-align light">
                  Hablemos sobre su empresa, proyecto o institución. Cuéntenos
                  sus necesidades y le presentaremos opciones actuales con
                  ejemplos de nuestros proyectos anteriores. Si dispone de
                  tiempo en su agenda, estaremos encantados de atenderle en
                  persona y si no es así, podremos mantener conversación via
                  correo, teléfono y videollamada.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Parallax imagen="madrid.webp" color="black-text">
          Detrás de todo buen funcionamiento existe un gran trabajo del equipo
          de sistemas
        </Parallax>
      </Layout>
    </>
  );
};